@import "//fonts.googleapis.com/css?family=Gudea:400,400i,700|Khand:300,600";

$theme-primary-color: #ec3a06;
$theme-secondary-color: #033a54;

$button-background: $theme-primary-color;
$column-gutter: 2.4em;
$eresults-branding: true;
$font-color: #3b3b3b;
$font-family: 'Gudea', sans-serif;
$font-size: 18px;
$font-weight: normal;
$header-font-color: $theme-secondary-color;
$header-font-family: 'Khand', sans-serif;
$header-font-weight: 600;
$h1-font-color: null;
$h1-font-weight: null;
$h2-font-color: null;
$h2-font-weight: null;
$h3-font-color: null;
$h3-font-weight: null;
$h4-font-color:  null;
$h4-font-weight: 300;
$h5-font-color: null;
$h5-font-weight: null;
$h6-font-color:  null;
$h6-font-weight: null;
$footer-background-color: $theme-secondary-color;
$footer-link-color: #0096e3;
$footer-cta-background-color: $theme-primary-color;
$footer-cta-header-font-color: #ffffff;
$footer-cta-link-color: #ffffff;
$footer-newsletter-background-color: $theme-secondary-color;
$footer-newsletter-header-color: #ffffff;
$footer-newsletter-font-color: #ffffff;
$footer-newsletter-link-color: #ffffff;
$footer-header-font-color: #ffffff;
$logo-height: 1.8;
$menu-horizontal-spacing: null;
$menu-font-color: null;
$menu-font-weight: 400;
$menu-active-font-color: $theme-primary-color;
$menu-active-font-weight: 700;
$fragment-spacing: 2em;
$link-color: $theme-primary-color;
$list-ordered-icon-color: $theme-primary-color;
$list-unordered-icon-color: $theme-primary-color;
$list-unordered-checks-icon: url('../../images/list-unordered-icon.png');
$inline-active-font-color: #ffffff;
$makers-mark-link-color: rgba( $font-color, .35 );
$makers-mark-link-hover-color: $font-color;
